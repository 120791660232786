import { useAuditTrailReportVM } from "presentation/hook/AuditTrailReport/useAuditTrailReportVM";
import { memo, useEffect, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import AuditTrailReportPanel from "./AuditTrailReportPanel";


const AuditTrailReportMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const auditTrailReportVM = useAuditTrailReportVM();


    useEffect(() => {
        const initialScreen = async () => {
            try {
                const results = await Promise.allSettled([
                    auditTrailReportVM.loadDropdownOption(),
                ]);
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {
            auditTrailReportVM.initAuditTrailReportList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }).catch(error => {
            setIsLoading(false)
        });

    }, [auditTrailReportVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
            {<>
                <AuditTrailReportPanel /></>}
        </div>
    </>
}

export default memo(AuditTrailReportMaintenance);