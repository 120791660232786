import { AuditTrailReportEntity } from "domain/entity/AuditTrailReport/AuditTrailReportEntity";
import { AuditTrailReportRepository } from "domain/repository/AuditTrailReport/AuditTrailReportRepo";
import { PrintQueueRepository } from "domain/repository/Common/PrintQueue";
import { RemoteFileInfoRepository } from "domain/repository/Common/RemoteFileInfoRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import _ from "lodash";
import { AuditTrailReportModel } from "presentation/model/AuditTrailReport/AuditTrailReportModel";
import { auditTrailReportEnabledField } from "presentation/utils/report/ReportEnabledFieldUtils";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface AuditTrailReportVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<AuditTrailReportModel>> | ((value: SetStateAction<AuditTrailReportModel>) => void),
    ],
    auditTrailReportRepo: AuditTrailReportRepository,
    printQueueRepo: PrintQueueRepository,
    remoteFileInfoRepo: RemoteFileInfoRepository,
    companyRepo: CompanyRepository,
}

export const AuditTrailReportVM = ({ dispatch, auditTrailReportRepo, printQueueRepo, remoteFileInfoRepo, companyRepo }: AuditTrailReportVMProps) => {
    const [auditTrailReportDispatch] = dispatch;

    const loadDropdownOption = async () => {
        await printQueueRepo.getAllPrintQueues().then(
            datas => {
                let printQueues = datas?.filter(entity => entity.activeInd === 'Y');

                const dataObj = printQueues.reduce((acc, item) => {
                    const key = item.id;
                    const value = item.printQueueName;
                    acc[value] = key;
                    return acc;
                }, {} as { [key: string]: number });


                let printQueueDropdownOptions = printQueues?.map((printQueue) => ({
                    dropdownLabel: printQueue.printQueueName,
                    tagLabel: printQueue.printQueueName,
                    value: printQueue.printQueueName,
                })) ?? []
                printQueueDropdownOptions = _.orderBy(printQueueDropdownOptions, "dropdownLabel");

                auditTrailReportDispatch(prevState => ({
                    ...prevState,
                    printQueueIdMap: dataObj,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        printQueueDropdownOptions: printQueueDropdownOptions,
                    },
                    currentReport: {
                        ...prevState.currentReport,
                        printer: printQueueDropdownOptions.length > 0 ? printQueueDropdownOptions[0].value : '',
                    }
                }))
            }
        )

        await remoteFileInfoRepo.getFileLoc().then(
            fileLocs => {
                let fileLocationDropdownOptions = fileLocs?.map((fileLoc) => ({
                    dropdownLabel: fileLoc,
                    tagLabel: fileLoc,
                    value: fileLoc,
                })) ?? []
                fileLocationDropdownOptions = _.orderBy(fileLocationDropdownOptions, "dropdownLabel");

                auditTrailReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        fileLocationDropdownOptions: fileLocationDropdownOptions,
                    },
                    currentReport: {
                        ...prevState.currentReport,
                        fileLocation: fileLocationDropdownOptions.length > 0 ? fileLocationDropdownOptions[0].value : '',
                    },
                    enabledField: {
                        ...prevState.enabledField,
                        companyCode: false,
                        billingType: false,
                        docNoFrom: true,
                        docNoTo: true,
                        docDateFrom: true,
                        docDateTo: true,
                        transactionDateFrom: false,
                        transactionDateTo: false,
                        proposalNo: false,
                        adjustmentDateFrom: false,
                        adjustmentDateTo: false
                    }
                }))
            }
        )



        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                auditTrailReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: [
                            ...companyCodeDropdownOptions],
                    }
                }))
            }
        )

    }

    const initAuditTrailReportList = async () => {
        await auditTrailReportRepo.initAuditTrailReportList().then(data => {

            const [reportIds, reportNames] = data.reduce<[string[], string[]]>((acc, item) => {
                const [key, value] = item.split(':', 2);
                acc[0].push(key);
                acc[1].push(value.trim());
                return acc;
            }, [[], []] as [string[], string[]]);

            const dataObj = data.reduce((acc, item) => {
                const [key, value] = item.split(':', 2);
                acc[value] = key.trim();
                return acc;
            }, {} as { [key: string]: string });

            const reportTypeDropdownOption = reportNames?.map((report) => ({
                dropdownLabel: report,
                tagLabel: report,
                value: report,
            })) ?? []
            auditTrailReportDispatch(prevState => {
                return {
                    ...prevState,
                    reportIdTypeMap: dataObj,
                    reportTypeList: reportNames,
                    reportIdList: reportIds,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        reportTypeDropdownOptions: reportTypeDropdownOption
                    },
                    currentReport: {
                        ...prevState.currentReport,
                        reportType: reportTypeDropdownOption.length > 0 ? reportTypeDropdownOption[0].value : '',
                    }
                }
            })
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        auditTrailReportDispatch(prevState => {
            return {
                ...prevState,
                currentReport: {
                    ...prevState.currentReport,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onReportTypeChanged = async (reportType: string) => {
        const enabledField = auditTrailReportEnabledField(reportType);
        auditTrailReportDispatch(prevState => ({
            ...prevState,
            enabledField: enabledField,
            currentReport: {
                ...prevState.currentReport,
                companyCode: "",
                billingType: "",
                docDateFrom: null,
                docDateTo: null,
                docNoFrom: null,
                docNoTo: null,
                transactionDateFrom: null,
                transactionDateTo: null,
                proposalNo: null,
                adjustmentDateFrom: null,
                adjustmentDateTo: null,
            },
        }))
    }

    const onResetClick = async () => {
        auditTrailReportDispatch(prevState => ({
            ...prevState,
            currentReport: {
                ...prevState.currentReport,
                reportType: prevState.dynamicOptions.reportTypeDropdownOptions.length > 0 ? prevState.dynamicOptions.reportTypeDropdownOptions[0].value : '',
                printer: prevState.dynamicOptions.printQueueDropdownOptions.length > 0 ? prevState.dynamicOptions.printQueueDropdownOptions[0].value : '',
                fileLocation: prevState.dynamicOptions.fileLocationDropdownOptions.length > 0 ? prevState.dynamicOptions.fileLocationDropdownOptions[0].value : '',
                companyCode: "",
                billingType: "",
                docDateFrom: null,
                docDateTo: null,
                docNoFrom: null,
                docNoTo: null,
                transactionDateFrom: null,
                transactionDateTo: null,
                proposalNo: null,
                adjustmentDateFrom: null,
                adjustmentDateTo: null,
            },
        }))
    }

    const onPrintClick = async (reportIdTypeMap: { [key: string]: string }, entity: AuditTrailReportEntity) => {
        let data = { ...entity };
        data = {
            ...data,
            reportType: reportIdTypeMap[entity.reportType],
        }
        return await auditTrailReportRepo.print(data);
    }


    const onDownloadClick = async (reportIdTypeMap: { [key: string]: string }, entity: AuditTrailReportEntity, downloadType?: string) => {
        let data = { ...entity };
        data = {
            ...data,
            reportType: reportIdTypeMap[entity.reportType],
            exportType: downloadType,
        }
        return await auditTrailReportRepo.download(data).then((data) => {
            if (data && data.success) {
                const res = data.data;
                for (const key in res) {
                    if (res[key]) {
                        const tempData = res[key] as string;
                        let base64 = tempData;
                        let bstr = atob(base64);
                        let n = bstr.length;
                        let u8arr = new Uint8Array(n);
                        while (n--) {
                            u8arr[n] = bstr.charCodeAt(n)
                        }
                        if (downloadType) {
                            const blob = downloadType === "CSV" ? new Blob([u8arr], { type: 'text/csv' }) : new Blob([u8arr], { type: 'application/rtf' });
                            const url = URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = key + "." + downloadType.toLowerCase();
                            a.style.display = 'none';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            document.body.removeChild(a);
                        }
                    }
                }
                return "success";
            } else {
                return data.data ?? "Download Failed.";

            }
        })
    }
    const onViewClick = async (reportIdTypeMap: { [key: string]: string }, entity: AuditTrailReportEntity) => {
        let data = { ...entity };
        data = {
            ...data,
            reportType: reportIdTypeMap[entity.reportType],
        }
        return await auditTrailReportRepo.view(data).then((data) => {
            if (data && data.success) {
                const res = data.data;
                for (const key in res) {
                    if (res[key]) {
                        const tempData = res[key] as string;
                        let base64 = tempData;
                        let bstr = atob(base64);
                        let n = bstr.length;
                        let u8arr = new Uint8Array(n);
                        while (n--) {
                            u8arr[n] = bstr.charCodeAt(n)
                        }

                        const blob = new Blob([u8arr], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        window.open(url, '_blank');
                        URL.revokeObjectURL(url);

                    }
                }
                return "success";
            } else {
                return data.data ?? "Download Failed.";

            }
        })
    }


    const onExportClick = async (exportType: string, reportIdTypeMap: { [key: string]: string }, entity: AuditTrailReportEntity) => {
        let data = { ...entity };
        data = {
            ...data,
            reportType: reportIdTypeMap[entity.reportType],
            exportType: exportType,
        }
        return await auditTrailReportRepo.exportReport(data);
    }

    return {
        onPrintClick: onPrintClick,
        onExportClick: onExportClick,
        onViewClick: onViewClick,
        onDownloadClick: onDownloadClick,
        onResetClick: onResetClick,
        onReportTypeChanged: onReportTypeChanged,
        onHeaderFieldChange: onHeaderFieldChange,
        loadDropdownOption: loadDropdownOption,
        initAuditTrailReportList: initAuditTrailReportList,
    }
}